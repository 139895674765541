import React from 'react'

const renderProperty = (property, label) => (
  <>
    <br />
    {label}
    <oma-link to={property} />
  </>
)

const Contact = ({ name, title, phone, mobile, email, extraInfo }) => (
  <div className='contact'>
    <p>
      <span className='contact__name'>{name}</span>
      <span>{extraInfo}</span>
      <br />
      {title}
      {phone && renderProperty(phone, 'Tel: ')}
      {mobile && renderProperty(mobile, 'Mobil: ')}
      {email && renderProperty(email, 'Email: ')}
    </p>
  </div>
)

export default Contact
