import React from 'react'
import { graphql } from 'gatsby'

import Contact from 'components/Contact'
import InfoSection from 'components/InfoSection'
import Layout from 'components/Layout'
import SectionWithColumnLayout from 'components/SectionWithColumnLayout'

const Kontakt = ({ data }) => (
  <Layout
    pageTitle='Kontakt'
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
    headerBackgroundPosition='30% 50%'
  >
    <oma-grid-row contentAlignedWithGrid='true'>
      <SectionWithColumnLayout title='Kontakt' contentClassName='contacts'>
        <Contact
          name='Thomas Markström'
          title='Redovisningskonsult'
          phone='+46334751153'
          mobile='+46706427709'
          email='thomas@ertan.se'
        />
        <Contact
          name='Anna Norman'
          title='Affärsjurist/Redovisningskonsult'
          phone='+46334751154'
          mobile='+46723583742'
          email='anna@ertan.se'
        />
        <Contact
          name='Mohamad Ajami'
          title='Redovisningskonsult'
          phone='+46334751136'
          mobile='+46735222756'
          email='mohamad@ertan.se'
        />
      </SectionWithColumnLayout>
    </oma-grid-row>
    <InfoSection />
  </Layout>
)

export const query = graphql`
  {
    header: file(relativePath: { eq: "telefon-hand.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: FULL_WIDTH)
      }
    }
  }
`

export default Kontakt
